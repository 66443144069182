import React from 'react';

import moment from 'moment';

import "../../styles/gamestatus.css";

import {
  Popup, Icon, Label, Header, Grid, Message
} from 'semantic-ui-react'

import Constants from "../../constants/constants";
import Util from "../../util";

const GameStatus = ({ status, time, sport, ko, points, bet, sameDriverAlert = false, includesET, bonusPoints, competition }) => {


  return (
    <Header
      attached='top' 
      textAlign='center'
      className='game-status no-padding'
    >
      <Grid className='no-side-margin'>
        <Grid.Column width={2} className='game-time'>
          <p style={{ fontWeight: 'lighter', textAlign: 'left', paddingLeft: '10px', textWrapMode: 'nowrap' }}>
            { competition?.name }
          </p>
        </Grid.Column>
        <Grid.Column width={12} className='game-time' >
        { (status === Constants.MATCH_STATUS__SCHEDULED || status === Constants.MATCH_STATUS__POSTPONED) ?
          (
            <>
              { 
                (
                  (typeof(bet) === 'string' && bet !== '') || // bet is string in 1x2Match types
                  (typeof(bet) === 'object' && Object.keys(bet).length) // bet is an object in PodiumMatch and Event types
                )  ? // Check if either Match or PodiumMatch has a bet
                  (
                    <Label circular color='green' basic size='small' className='no-margin no-border'>
                      <Icon name='save' className='game-saved-bet-icon' />
                    </Label>
                  ) :
                  (
                    <Popup
                      trigger={
                        <Icon name='exclamation' color='yellow' className='no-margin'/>
                      }
                      content='No prediction saved!'
                      size='mini'
                      position='bottom center'
                    />
                  )
              }
              { status === Constants.MATCH_STATUS__SCHEDULED ?
                (
                  <>
                    <Icon name='clock outline' className='no-margin'/>
                    {moment(time).format("HH:mm")}
                  </>
                ) :
                (
                  <Label circular basic size='small' className='no-margin no-border'>
                      <Icon name='calendar times outline' className='no-margin game-saved-bet-icon'/>
                      POSTPONED
                    </Label>
                )
              }
              { ko ?
                (includesET ?
                  (
                    <Popup
                      trigger={
                        <Label circular color='green' basic size='small' className='no-border no-margin'>
                          ET
                          <Icon name='question circle' className='no-margin' />
                        </Label>
                      }
                      content='The Extra Time (ET) is considered in the result'
                      size='mini'
                      position='bottom center'
                    />
                  ):
                  (
                    <Popup
                      trigger={
                        <Label circular color='red' basic size='small' className='no-border no-margin'>
                          NO ET
                          <Icon name='question circle' className='no-margin' />
                        </Label>
                      }
                      content='Only the Regular Time (FT) result counts'
                      size='mini'
                      position='bottom center'
                    />
                  )
                ) :
                (<></>)
              }
              {
                bonusPoints ?
                (
                  <>
                    <Label circular color='purple' size='small'>
                      {'x' + bonusPoints}
                    </Label>
                    <Label basic color='purple' size='small' className='no-border no-margin no-padding'>
                      MULTIPLIER
                    </Label>
                  </>
                ) :
                (<></>)
              }
            </>
          ) :
          (
            <>
              { status === Constants.MATCH_STATUS__PLAYING ?
                (
                  <Icon name={`${sport}`} fitted className='loading'/>
                ):
                (
                  <>
                    {Util.renderMatchPoints(points)}
                  </>
                )
              }
            </>
          )
        }
        </Grid.Column>
      </Grid>
      { sameDriverAlert ?
        (
          <Message size='tiny' warning className='reduced-padding'>
            <Icon name='exclamation' />
            Points can only be earned once for a driver selected in multiple positions
          </Message>
        ):
        (<></>)
      }
    </Header>
  );
};

export default GameStatus;